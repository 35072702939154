
/**
 * pre prod
 */
export const protocol = "";
export const BASE_URL_API = process.env.REACT_APP_BASE_URL_API;
export const BASE_URL_LOCAL = process.env.REACT_APP_BASE_URL_LOCAL; //process.env.REACT_APP_BASE_URL;
export const sk = process.env.REACT_APP_pk;
export const pk = process.env.REACT_APP_pk;
export const logout_url = process.env.REACT_APP_logout_url;
// export const logout_url = "https://ats-app.dvapply.com";